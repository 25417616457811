<template>
  <div class="product-list">
    <div class="product-list-head">
      <strong>IoT开发资源国产化</strong>
      <p>致力于为您提供<span>免费的</span>开发资源</p>
    </div>
    <div class="product-list-desc">
      <span>基于OpenHarmony聚焦智能物联网的技术与持续创新。专注于行业开发板、模组等配套开发资源的多场景应用，鸿联联创平台致力于为您提供免费的开发资源，助力您的国产替代迁移需求低成本实现，赋能千行百业。</span>
    </div>
    <div class="product-list-type">
      <div @click="$router.push('/product/list?type=dev')">
        <span>开发板</span>
        <p>国产SOC系列开发板</p>
      </div>
      <div @click="$router.push('/product/list?type=soc')">
        <span>SOC</span>
        <p>主控SOC国产化</p>
      </div>
    </div>
    <div class="product-list-box">
      <div class="product-list-title">
        <p>开发板</p>
        <span @click="$router.push('/product/list?type=dev')"
        >查看更多 <img src="@/assets/img/gdicon.png" alt="查看更多"
        /></span>
      </div>
      <div class="product-dev-list-box">
        <div
          class="product-iot-list"
          v-for="el in devList"
          :key="el.index"
          @click="productInfo(el.id, 'dev')"
        >
          <div class="product-iot-list-img">
            <span><img src="@/assets/img/tjtb.png" alt="推荐"/></span>
            <v-img
              :attachment-id="el.coverAttmId"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </div>
          <div class="product-iot-list-info">
            <strong>{{ el.name }}</strong>
            <span v-if="el.datSocs"
            >SOC：<template v-for="i in el.datSocs">{{
                i.name
              }}</template></span
            >
            <span>厂商：{{ el.isvName }}</span>
            <p v-if="el.sceneTagName">
              <template v-for="(e, i) in el.sceneTagName.split(',')">
                <span v-if="i < 2" :key="i">{{ e }}</span>
              </template>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="product-list-box">
      <div class="product-list-title">
        <p>SOC</p>
        <span @click="$router.push('/product/list?type=soc')"
        >查看更多 <img src="@/assets/img/gdicon.png" alt="查看更多"
        /></span>
      </div>
      <div class="product-dev-list-box">
        <div
          class="product-iot-list"
          v-for="el in socList"
          :key="el.index"
          @click="productInfo(el.id, 'soc')"
        >
          <div class="product-iot-list-img">
            <span><img src="@/assets/img/tjtb.png" alt="推荐"/></span>
            <v-img
              :attachment-id="el.coverAttmId"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </div>
          <div class="product-iot-list-info">
            <strong>{{ el.name }}</strong>
            <span>型号：{{ el.modelNum || '/' }}</span>
            <span>厂商：{{ el.isvName }}</span>
            <p v-if="el.sceneTagName">
              <template v-for="(e, i) in el.sceneTagName.split(',')">
                <span v-if="i < 2" :key="i">{{ e }}</span>
              </template>
            </p>
          </div>
        </div>
      </div>
    </div>
    <product-footer/>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getIotSocList, getIotDevList,getProductList } from '@/api/iot'
import VImg from '@/components/VImg'
import productFooter from '../components/productFooter.vue'
import share from '../components/share.vue'

export default {
  components: {
    VImg,
    productFooter,
    share
  },
  data () {
    return {
      devList: [],
      socList: [],
      shareInfo: {
        title: '享｜开发资源',
        desc: '基于OpenHarmony聚焦智能物联网的技术与持续创新。专注于行业开发板、模组等配套开发资源的多场景应用，鸿联联创平台致力于为您提供免费的开发资源，助力您的国产替代迁移需求低成本实现，赋能千行百业。',
        img: `${process.env.VUE_APP_OBS_PREFIX}/1553280480000921602`
      }
    }
  },
  mounted () {
    document.title = '享｜开发资源'
    this.loadgetIotDevList()
    this.loadgetIotSocList()
    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }
  },
  methods: {
    // 开发板列表
    loadgetIotDevList () {
      getProductList(
        {
          current: 1,
          size: 4,
        },
        {
          status: '2',
          categoryId:'',
          type:'dev',
        }
      ).then((res) => {
        this.devList = res.records
      })
    },
    // SOC列表
    loadgetIotSocList () {
      getProductList(
        {
          current: 1,
          size: 4,
        },
        {
          status: '2',
          categoryId:'',
          type:'soc',
        }
      ).then((res) => {
        this.socList = res.records
      })
    },
    productInfo (id, type) {
      this.$router.push({
        path: '/product/productInfo',
        query: {
          id,
          type
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.product-list {
  min-height: calc(100vh - 56px);
  background: #f6f6f6;
  // padding-bottom: 80px;
  .product-list-head {
    background: #fff;
    padding: 38px 24px;
    background: url(../../assets/img/5020@2x.png) no-repeat;
    background-size: 100% 100%;
    height: 210px;

    > strong {
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      display: block;
      margin-bottom: 10px;
    }

    > p {
      font-size: 12px;
      line-height: 18px;
      color: #333333;
      margin-bottom: 0;

      > span {
        color: #EA0B06;
      }
    }
  }

  .product-list-desc {
    padding: 0 16px;
    position: relative;
    z-index: 2;
    margin-top: -50px;

    > span {
      display: block;
      padding: 24px 12px;
      font-size: 12px;
      line-height: 18px;
      color: #666666;
      background: url(../../assets/img/5020@2xbg.png) no-repeat;
      background-size: 100% 100%;
      border-radius: 4px;
    }
  }

  .product-list-type {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    > div {
      width: 167px;
      height: 76px;
      border-radius: 4px;
      overflow: hidden;
      padding: 14px 16px;

      &:nth-child(1) {
        background: url(../../assets/img/11@2x.png) no-repeat;
        background-size: 100% 100%;
      }

      &:nth-child(2) {
        background: url(../../assets/img/22@2x.png) no-repeat;
        background-size: 100% 100%;
      }

      > span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 7px;
      }

      > p {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #999999;
        margin-bottom: 0;
      }
    }
  }

  .product-list-box {
    .product-list-title {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #333;
      }

      > span {
        display: flex;
        align-items: center;
        color: #666;
        font-size: 12px;

        > img {
          width: 6px;
          height: 11px;
          margin-left: 8px;
        }
      }
    }

    .product-dev-list-box {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .product-iot-list {
        width: 167px;
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        margin-bottom: 9px;

        .product-iot-list-img {
          position: relative;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;

          > span {
            position: absolute;
            width: 32px;
            height: 19px;
            left: 10px;
            top: -4px;

            > img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          > img {
            display: block;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            width: 90px;
          }
        }

        .product-iot-list-info {
          padding: 8px;

          > strong {
            display: block;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
            color: #333333;
            margin-bottom: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            color: #666666;
            display: block;
            margin-bottom: 5px;
          }

          > p {
            display: flex;
            margin-bottom: 0;

            > span {
              height: 19px;
              background: #f7f7f7;
              border-radius: 2px;
              padding: 0 6px;
              font-size: 12px;
              font-weight: 400;
              line-height: 19px;
              color: #666;
              padding: 0 6px;
              margin-right: 4px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
